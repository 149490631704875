import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU11Blues() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 11's - Blues</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u11-blues.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">

            <p>Whilst our U10’s are well established and playing organised football in a fun environment, new players are welcome to come along and be part of our squad.</p>

            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Fxitures</h3>
            <TheFATable leagueId="990023268"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 6, DoB 01/09/2011 - 31/08/2012</dd>
                <dt>Manager(s)</dt>
                <dd>Sini Savic</dd>
                <dt>Training</dt>
                <dd>Saturday 10:30am on Bedquilts</dd>
                <dt>League</dt>
                <dd>Garforth Junior League</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:sinisa_savic@hotmail.co.uk">sinisa_savic@hotmail.co.uk</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsors</div>
                <img src="/sponsor-gma.jpg" />
                <img src="/sponsor-istanbul-catering.jpg" />
                <img src="/sponsor-mn-property-solutions.jpg" class="p-4" />
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

